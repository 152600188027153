<template>
  <el-dialog :title="options.title" :visible.sync="visible" :close-on-click-modal="false" width="600px">
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="confirmForm">确 定</el-button>
    </span>

    <el-form ref="form" :model="form" label-width="100px">

      <el-form-item label="支付原因" :rules="[{ required: true, message: '请输入支付原因'}]">
        <el-input v-model="form.name"></el-input>
      </el-form-item>

      <el-form-item label="支付金额" :rules="[{ required: true, message: '请输入支付金额', trigger: 'blur'}]">
        <el-input-number v-model="form.fee" controls-position="right"></el-input-number><span style="margin-left: 10px;">万元</span>
      </el-form-item>

      <el-form-item label="上传文件" :rules="[{ required: true, message: '请上传文件'}]">
        {{ form.url }}
        <el-upload style="display: inline-block;"
          action="/api/upload"
          :show-file-list="false"
          :on-change="uploadChange"
          :on-error="e => $message.error(e.message)"
          >
          <el-button slot="trigger" type="primary" icon="el-icon-upload" size="mini">上传</el-button>
        </el-upload>
      </el-form-item>

    </el-form>
  </el-dialog>
</template>

<script>

import _ from 'lodash';
export default {
  data() {
    return {
      visible: false,
      options: {},
      form: {},
      defaultForm: {  },

      zjnf_options: [],
      cash_pool_options: [],
    }
  },

  methods: {
    async show(initForm, options = {}) {
      this.form = _.assign({}, this.defaultForm, initForm, {});
      this.options = options;
      this.visible = true;
    },

    async confirmForm() {
      try {
        await this.$api.execute('ycjh_cms.project.log.create', _.assign({
          type: '资金支付',
          fee_calc_type: 5,
        }, this.form, {
          payload: JSON.stringify(this.form)
        }));
        this.options.success && this.options.success();
        this.visible = false;
      } catch(e) {
        this.$notify({
          type: 'error',
          message: e.message
        });
      }
    },

    async uploadChange(e) {
      if(e.status === 'success') {
        const url = _.get(e, 'response[0].url');
        const name = _.last(url.split('/'))

        this.form = _.assign({}, this.form, { url, name })
        // const res = await this.$api.execute('ycjh_cms.project.log.create', {
        //   project_id: this.info.id,
        //   type: '设计阶段',
        //   name,
        //   payload: JSON.stringify({ url })
        // });
      }
    }
  }
}
</script>